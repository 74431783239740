export default function FloatingBlur({items}) {

    if (!items) return null;

    return (
        <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
            {items.map(({top = 'auto', left = 'auto', width, height = width, className}, key) => {
                return (
                    <div className={`absolute rounded-[50%] bg-gradient-radial 2transition-all 2duration-1000 ${className}`} key={`Floating: ${key}`}
                         style={{
                             top,
                             left,
                             width,
                             height
                         }}
                    >

                    </div>
                )
            })}
        </div>
    )
}