import FloatingBlur from "../FloatingBlur";
import {useEffect, useState} from "react";

const config = {
    "home": [
        {
            top: ['4.5%', '-10%'],
            left: ['-30%', '-5%'],
            width: '68vw',
            height: '56vw',
            className: 'from-orange-600/[0.7] to-orange-600/0'
        },

        {
            top: ['5.5%', '8%'],
            left: ['40%', '40%'],
            width: '100vw',
            height: '53vw',
            className: 'from-lilac-600/[0.7] to-lilac-600/0'
        },
        {
            top: '-5%',
            left: '-40%',
            width: '66vw',
            height: '65vw',
            className: 'from-lilac-600/[0.7] to-lilac-600/0'
        },
        {
            top: ['10.5%', '13%'],
            left: ['-80%', '0'],
            width: ['150vw', '58vw'],
            height: ['150vw', '62vw'],
            className: 'from-orange-600/[0.3] to-orange-600/0'
        },
        {
            top: ['13.5%', '0'],
            left: ['30%', '0'],
            width: ['150vw', '0'],
            height: ['150vw', '0'],
            className: 'from-purple-600/[0.3] to-purple-600/0 md:hidden'
        },
        {
            top: ['48%', '45%'],
            left: ['58%', '-35%'],
            width: ['150vw', '55vw'],
            height: ['150vw', '49vw'],
            className: 'from-orange-600/[0.7] to-orange-600/0'
        },
        {
            top: ['42%', '33%'],
            left: ['-40%', '-35%'],
            width: ['100vw', '66vw'],
            height: ['100vw', '65vw'],
            className: 'from-lilac-600/[0.7] to-lilac-600/0'
        },
        {
            top: '63%',
            left: '68%',
            width: '60vw',
            height: '59vw',
            className: 'from-lilac-600/[0.7] to-lilac-600/0 hidden md:block'
        },
        {
            top: '73%',
            left: '68%',
            width: '61vw',
            height: '49vw',
            className: 'from-orange-600/[0.6] to-orange-600/0'
        },
    ],
    "jobs-in-the-netherlands": [
        {
            top: ['0', '-30%'],
            left: ['30%', '25%'],
            width: '84vw',
            height: '80vw',
            className: 'from-lilac-600/[0.7] to-lilac-600/0'
        },
        {
            top: ['3.5%', '-8%'],
            left: ['-35%', '80%'],
            width: ['100vw', '50vw'],
            height: ['100vw', '38vw'],
            className: 'from-purple-800/[0.7] to-purple-800/0'
        },
        {
            top: ['50%', '55%'],
            left: ['-75%', '-50%'],
            width: ['100vw', '61vw'],
            height: ['100vw', '44vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
        {
            top: ['21%', '25%'],
            left: ['65%', '-55%'],
            width: ['100vw', '90vw'],
            height: ['100vw', '64vw'],
            className: 'from-lilac-600/[0.7] to-lilac-600/0'
        },
        {
            top: ['62%', '25%'],
            left: ['45%', '80%'],
            width: ['150vw', '68vw'],
            height: ['150vw', '51vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
        {
            top: '45%',
            left: '60%',
            width: '83vw',
            height: '82vw',
            className: 'from-lilac-600/[0.7] to-lilac-600/0 hidden md:block'
        },
        {
            top: ['83%', '87%'],
            left: ['-100%', '-18%'],
            width: ['150vw', '40vw'],
            height: ['150vw', '40vw'],
            className: 'from-lilac-600/[0.7] to-lilac-600/0'
        },
    ],
    "part-time-job-in-amsterdam": [
        {
            top: ['0', '-23%'],
            left: '45%',
            width: '84vw',
            height: '80vw',
            className: 'from-orange-600/[0.7] to-orange-600/0'
        },
        {
            top: ['6.5%', '20%'],
            left: ['-45%', '73%'],
            width: '91vw',
            height: ['91vw', '68vw'],
            className: 'from-orange-600/[0.7] to-orange-600/0'
        },
        {
            top: ['22%', '53%'],
            left: ['55%', '65%'],
            width: ['100vw', '45vw'],
            height: ['100vw', '44vw'],
            className: 'from-lilac-600/[0.7] to-lilac-600/0'
        },
        {
            top: ['50%', '40%'],
            left: ['-80%', '-70%'],
            width: ['100vw', '90vw'],
            height: ['100vw', '64vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
        {
            top: '63%',
            left: '55%',
            width: '150vw',
            height: '150vw',
            className: 'from-orange-800/[0.7] to-orange-800/0 md:hidden'
        },
        {
            top: ['88%', '93%'],
            left: ['-65%', '-10%'],
            width: ['100vw', '45vw'],
            height: ['100vw', '44vw'],
            className: 'from-lilac-600/[0.7] to-lilac-600/0'
        },
    ],
    "job-offers": [
        {
            top: ['6%', '-12%'],
            left: ['-35%', '-80%'],
            width: ['80vw', '118vw'],
            height: ['80vw', '89vw'],
            className: 'from-lilac-800/[0.7] to-lilac-800/0'
        },
        {
            top: ['9%', '6%'],
            left: ['74%', '85%'],
            width: ['120vw', '90vw'],
            height: ['120vw', '67vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0 md:from-lilac-800/[0.7] md:to-lilac-800/0 '
        },
        {
            top: ['33%', '38%'],
            left: ['80%', '88%'],
            width: ['100vw', '69vw'],
            height: ['100vw', '52vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
        {
            top: ['60%', '57%'],
            left: ['68%', '-50%'],
            width: ['150vw', '69vw'],
            height: ['150vw', '52vw'],
            className: 'from-lilac-800/[0.7] to-lilac-800/0'
        },
        {
            top: ['42%', '42%'],
            left: ['-70%', '-50%'],
            width: ['100vw', '69vw'],
            height: ['100vw', '52vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
    ],
    "about-us": [
        {
            top: '-25%',
            left: '-25%',
            width: '75vw',
            height: '56vw',
            className: 'from-orange-800/[0.7] to-orange-800/0 hidden md:block'
        },
        {
            top: '0',
            left: ['-60%', '-25%'],
            width: ['100vw', '48vw'],
            height: ['100vw', '36vw'],
            className: 'from-pink-800/[0.5] to-pink-800/0'
        },
        {
            top: ['10%', '18%'],
            left: ['-80%', '-30%'],
            width: ['100vw', '40vw'],
            height: ['100vw', '40vw'],
            className: 'from-lilac-800/[0.7] to-lilac-800/0'
        },
        {
            top: '23%',
            left: ['70%', '-72%'],
            width: ['150vw', '91vw'],
            height: ['150vw', '68vw'],
            className: 'from-pink-800/[0.7] to-pink-800/0'
        },
        {
            top: '43%',
            left: '90%',
            width: '38vw',
            height: '38vw',
            className: 'from-lilac-800/[0.7] to-lilac-800/0 hidden md:block'
        },
        {
            top: '65%',
            left: '80%',
            width: '38vw',
            height: '38vw',
            className: 'from-pink-800/[0.7] to-pink-800/0 hidden md:block'
        },
        {
            top: ['60%', '71%'],
            left: ['-150%', '-25%'],
            width: ['200vw', '45vw'],
            height: ['200vw', '44vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0 md:from-pink-800/[0.7] md:to-pink-800/0'
        },
    ],
    "how-it-works": [
        {
            top: '-10%',
            left: '63%',
            width: '55vw',
            height: '55vw',
            className: 'from-green-800/[0.7] to-green-800/0 hidden md:block'
        },
        {
            top: ['3%', '0'],
            left: ['-75%', '-55%'],
            width: ['100vw', '75vw'],
            height: ['100vw', '75vw'],
            className: 'from-green-800/[0.7] to-green-800/0'
        },
        {
            top: ['25%', '8%'],
            left: ['70%', '80%'],
            width: ['150vw', '55vw'],
            height: ['150vw', '55vw'],
            className: 'from-green-800/[0.7] to-green-800/0'
        },
        {
            top: ['65%', '48%'],
            left: ['-75%', '87%'],
            width: ['100vw', '55vw'],
            height: ['100vw', '55vw'],
            className: 'from-purple-800/[0.7] to-purple-800/0 '
        },
        {
            top: ['80%', '64%'],
            left: ['-75%', '-40%'],
            width: ['100vw', '55vw'],
            height: ['100vw', '55vw'],
            className: 'from-purple-800/[0.7] to-purple-800/0'
        },
    ],
    "faq": [
        {
            top: '-14%',
            left: '54%',
            width: '41vw',
            height: '31vw',
            className: 'from-orange-800/[0.7] to-orange-800/0 hidden md:block'
        },
        {
            top: ['8%', '-25%'],
            left: ['60%', '70%'],
            width: '119vw',
            height: ['119vw', '89vw'],
            className: 'from-blue-800/[0.7] to-blue-800/0'
        },
        {
            top: '2%',
            left: '-65%',
            width: '90vw',
            height: '67vw',
            className: 'from-blue-800/[0.7] to-blue-800/0'
        },
        {
            top: ['55%', '65%'],
            left: ['70%', '83%'],
            width: ['150vw', '69vw'],
            height: ['150vw', '52vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
        {
            top: ['75%', '80%'],
            left: ['-70%', '-50%'],
            width: ['100vw', '69vw'],
            height: ['100vw', '52vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
    ],
    "register": [
        {
            top: ['5%', '-5%'],
            left: ['60%', '50%'],
            width: ['150vw', '41vw'],
            height: ['150vw', '31vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
        {
            top: ['-2%', '-15%'],
            left: ['-25%', '70%'],
            width: '119vw',
            height: '89vw',
            className: 'from-orange-800/[0.7] to-orange-800/0 md:from-purple-800/[0.7] md:to-purple-800/0'
        },
        {
            top: ['25%', '15%'],
            left: ['-170%', '-65%'],
            width: ['200vw', '89vw'],
            height: ['200vw', '67vw'],
            className: 'from-purple-800/[0.7] to-purple-800/0'
        },
        {
            top: '55%',
            left: ['60%', '80%'],
            width: ['150vw', '69vw'],
            height: ['150vw', '52vw'],
            className: 'from-purple-800/[0.7] to-purple-800/0 md:from-orange-800/[0.7] md:to-orange-800/0'
        },
        {
            top: ['80%', '70%'],
            left: ['-70%', '-50%'],
            width: ['100vw', '69vw'],
            height: ['100vw', '52vw'],
            className: 'from-purple-800/[0.7] to-purple-800/0 md:from-orange-800/[0.7] md:to-orange-800/0'
        },
    ],
    "contacts": [
        {
            top: ['70%', '-25%'],
            left: ['10%', '-3%'],
            width: ['200vw', '60vw'],
            height: ['200vw', '30vw'],
            className: 'from-orange-800/[0.7] to-orange-800/0'
        },
        {
            top: ['-30%', '-12%'],
            left: ['-35%', '-22%'],
            width: ['100vw', '55vw'],
            height: ['100vw', '36vw'],
            className: 'from-pink-800/[0.5] to-pink-800/0'
        },
        {
            top: ['-15%', '58%'],
            left: ['70%', '-25%'],
            width: ['200vw', '40vw'],
            height: ['200vw', '40vw'],
            className: 'from-lilac-800/[0.7] to-lilac-800/0'
        },
    ],
};

export default function SectionFloating({scheme}) {
    const [items, setItems] = useState(null);

    useEffect(() => {
        if (window) {
            const handleResize = () => {
                if (!config[scheme]) {
                    setItems(null);
                    return null;
                }

                const mqIndex = window.innerWidth >= 768 ? 1 : 0;

                const newItems = config[scheme].map(({top, left, width, height, className}) => {
                    return {
                        top: Array.isArray(top) ? top[mqIndex] : top,
                        left: Array.isArray(left) ? left[mqIndex] : left,
                        width: Array.isArray(width) ? width[mqIndex] : width,
                        height: Array.isArray(height) ? height[mqIndex] : height,
                        className
                    }
                });

                setItems(newItems);
            }

            handleResize();
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }
    }, [scheme, setItems]);

    if (!items || !config[scheme]) return null;


    return (
        <FloatingBlur items={items}/>
    )
}